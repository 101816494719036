import React from "react"
import DashboardWidget from "../../widgets/DashboardWidget"

const HeroSectionAnimationAfterWidget = ({image}) => {
  return (
    <div className="bg-[#0A1E46] mb-[-2px]">
      <div className="synkli-section--horizontal-space text-center relative z-[2] home-hero--canvas-widget">
        {/* <ImageRenderer img={image.path} alt={image.alt} /> */}
        <DashboardWidget />
      </div>
    </div>
  )
}

export default HeroSectionAnimationAfterWidget
